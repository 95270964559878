<template>
    <div class="activity-icon-container">
        <div class="activity-icon">
            <i :class="icon" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ActivityIcon",
    props: {
        icon: {
            type: String,
            default: "fas fa-sms"
        }
    }
}
</script>

<style lang="scss">
.activity-icon-container {
    position: relative;
    height: 100%;
    margin-right: 20px;

    .activity-icon {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: var(--secondary-color);
        border-radius: 100%;
        top: 40px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    &::before, &::after {
        position: absolute;
        content: "";
        width: 1px;
        border-right-width: 1px;
        border-bottom-width: 0;
        border-top-width: 0;
        border-left-width: 0;
        border-color: #B3C1CB;
        border-style: dashed;
        left: calc(50% - 1px);
        z-index: -1;
    }

    &::before {
        height: calc(10% - 35px);
        top: 0;
    }

    &::after {
        height: calc(100% - 35px);
        bottom: 0;
    }
}
</style>
